const productData = [
      {
        id: 1,
        description: "Amazon Echo Dot 5th Gen",
        image: 'https://m.media-amazon.com/images/I/41U7UzQyiJL._AC_SX679_.jpg',
        link: "https://amzn.to/3Uqhyrw",
        wishlist: false,
        category:["electronics", "music"], 
      },
      {
        id: 2,
        description: "Spiralizer for Veggies and Salad",
        image: "https://m.media-amazon.com/images/I/810LGFx+r4L._AC_SX679_.jpg",
        link: "https://amzn.to/3UtFNFr",
        wishlist: false,
        category:["kitchenware", "food"], 
      },
      {
        id: 3,
        description: "Adjustable Laptop Stand",
        image: "https://m.media-amazon.com/images/I/510iU09qiyL._AC_SX679_.jpg",
        link: "https://amzn.to/3W7R1k8",
        wishlist: false,
        category:["appliance", "electronics"], 
      },
      {
        id: 4,
        description: "Resistance Bands",
        image: "https://m.media-amazon.com/images/I/71jV-UWFyFL._AC_SX679_.jpg",
        link: "https://amzn.to/3UgaUTE",
        wishlist: false,
        category:["exercise", "health"], 
      },
      {
        id: 5,
        description: "King and Queen Apron Set",
        image: "https://m.media-amazon.com/images/I/61vWc2VnTVL._AC_SX679_.jpg",
        link: "https://amzn.to/4b1H7EF",
        wishlist: false,
        category:["clothes", "kitchenware"], 
      },
      {
        id: 6,
        description: "Etekcity Digital Food Scale",
        image: "https://m.media-amazon.com/images/I/81hlqG9SxUL._SX522_.jpg",
        link: "https://amzn.to/3W8kWsl",
        wishlist: false,
        category:["food", "electronics"], 
      },
      {
        id: 7,
        description: "COSRX Snail Mucin 96% Power",
        image: "https://m.media-amazon.com/images/I/61p-wtpDraL._SX679_.jpg",
        link: "https://amzn.to/4aINyNo",
        wishlist: false,
        category:["appliance", "health"], 
      },
      {
        id: 8,
        description: "Sol De Janeiro Hair & Body Fragrance Mist",
        image: "https://m.media-amazon.com/images/I/51qU1-iyeTL._SX522_.jpg",
        link: "https://amzn.to/3QbeOvN",
        wishlist: false,
        category:["appliance", "health"], 
      },
      {
        id: 9,
        description: "Phillips 4100 Power Toothbrush",
        image: "https://m.media-amazon.com/images/I/61kcec5RGUL._AC_SX679_.jpg",
        link: "https://amzn.to/3xSKY8M",
        wishlist: false,
        category:["dental", "electronics"], 
      },
      {
        id: 10,
        description: "Golden Mountains Shilajit",
        image: "https://m.media-amazon.com/images/I/61RyyMxmceL._AC_SX679_.jpg",
        link: "https://amzn.to/4a2fbQj",
        wishlist: false,
        category:["health", "appliance"], 
      },
      {
        id: 11,
        description: "Peaks Pull Up bar",
        image: "https://m.media-amazon.com/images/I/61Ai3fZLi9L._AC_SX679_.jpg",
        link: "https://amzn.to/4bicdrV",
        wishlist: false,
        category:["exercise", "appliance"], 
      },
      {
        id: 12,
        description: "California Gold Nutrition Fish Oil",
        image: "https://m.media-amazon.com/images/I/61KUMakLYdL._AC_SX679_.jpg",
        link: "https://amzn.to/44npY6p",
        wishlist: false,
        category:["health", "medicine"], 
      },
      {
        id: 13,
        description: "21st Century Vitamin D3",
        image: "https://m.media-amazon.com/images/I/617Ko+C+WPL._AC_SX679_.jpg",
        link: "https://amzn.to/3WoVoqU",
        wishlist: false,
        category:["health", "appliance"], 
      },
      {
        id: 14,
        description: "Toddler Flower Straw Hat",
        image: "https://m.media-amazon.com/images/I/71K+KCGFwbL._AC_SX679_.jpg",
        link: "https://amzn.to/3JQvYeh",
        wishlist: false,
        category:["clothes"], 
      },
      {
        id: 15,
        description: "I Will Teach You to Be Rich",
        image: "https://m.media-amazon.com/images/I/51R5o-WAyjL.jpg",
        link: "https://amzn.to/3WzESVd",
        wishlist: false,
        category:["appliance", "books"], 
      },
      {
        id: 16,
        description: "Checkme Blood Pressure Monitor",
        image: "https://m.media-amazon.com/images/I/71Wbp+xjPLL._AC_SX679_.jpg",
        link: "https://amzn.to/44BjO2E",
        wishlist: false,
        category:["health", "electronics"], 
      },
      {
        id: 17,
        description: "Xylitol Gum",
        image: "https://m.media-amazon.com/images/I/61v2IZP1euL._SY522_.jpg",
        link: "https://amzn.to/45hVcw3",
        wishlist: false,
        category:["health", "medicine"], 
      },
      {
        id: 18,
        description: "Nespresso Machine",
        image: "https://m.media-amazon.com/images/I/716M8-NNuSL._AC_SX679_.jpg",
        link: "https://amzn.to/4dAHGXk",
        wishlist: false,
        category:["electronics", "appliance", "kitchenware"], 
      },
      {
        id: 19,
        description: "Toddler Water Shoes",
        image: "https://m.media-amazon.com/images/I/71PoJNokumL._AC_SY695_.jpg",
        link: "https://amzn.to/4fxCGVl",
        wishlist: false,
        category:["clothes"], 
      },
      {
        id: 20,
        description: "Apple airtag",
        image: "https://m.media-amazon.com/images/I/713xuNx00oS._AC_SX679_.jpg",
        link: "https://amzn.to/3WTA7Wc",
        wishlist: false,
        category:["electronics"], 
      },
      {
        id: 21,
        description: "Kids Headphones",
        image: "https://m.media-amazon.com/images/I/51QKUBPCNOL._AC_SX679_.jpg",
        link: "https://amzn.to/4dev5JR",
        wishlist: false,
        category:["electronics", "music"], 
      },
      {
        id: 22,
        description: "Teeth-whitening strips",
        image: "https://m.media-amazon.com/images/I/71drDomomAL._AC_SX679_PIbundle-28,TopRight,0,0_SH20_.jpg",
        link: "https://amzn.to/4cgHcVi",
        wishlist: false,
        category:["health"], 
      },
      {
        id: 23,
        description: "Shark Corded Stick Vacuum",
        image: "https://m.media-amazon.com/images/I/41FOfMwkw1L._AC_SX679_.jpg",
        link: "https://amzn.to/3LSvk18",
        wishlist: false,
        category:["appliance", "electronics"], 
      },
      {
        id: 24,
        description: "Baby Car Camera",
        image: "https://m.media-amazon.com/images/I/71H0sRwiabL._AC_SX679_.jpg",
        link: "https://amzn.to/3AnMwsW",
        wishlist: false,
        category:["electronics"], 
      },
      {
        id: 25,
        description: "Lunchable Snack Containers",
        image: "https://m.media-amazon.com/images/I/71AjJmTm70L._AC_SX679_.jpg",
        link: "https://amzn.to/3SSs1uK",
        wishlist: false,
        category:["food", "kitchenware"], 
      },
      {
        id: 26,
        description: "Men's Crew T-Shirts",
        image: "https://m.media-amazon.com/images/I/51wDsZxtTLL._AC_SX679_.jpg",
        link: "https://amzn.to/3X8YNt1",
        wishlist: false,
        category:["clothes"], 
      },
      {
        id: 27,
        description: "Car Phone Holder & Mount",
        image: "https://m.media-amazon.com/images/I/81AACVoXBDL._AC_SX679_.jpg",
        link: "https://amzn.to/3yGFBuc",
        wishlist: false,
        category:["appliance"], 
      },
      {
        id: 28,
        description: "Pimple Patch Stickers",
        image: "https://m.media-amazon.com/images/I/51YlAYwPx6L._SX679_.jpg",
        link: "https://amzn.to/4dHuHTV",
        wishlist: false,
        category:["health"], 
      },
      {
        id: 29,
        description: "FreeSip Stainless Steel Water Bottle",
        image: "https://m.media-amazon.com/images/I/712ajgjxvdL._AC_SX679_.jpg",
        link: "https://amzn.to/3X9wFHq",
        wishlist: false,
        category:["kitchenware", "appliance"], 
      },
      {
        id: 30,
        description: "Crossbody Backpack",
        image: "https://m.media-amazon.com/images/I/51e67gWhQQL._AC_SY695_.jpg",
        link: "https://amzn.to/3YMvqip",
        wishlist: false,
        category:["appliance"], 
      },
      {
        id: 31,
        description: "Deep Twin XL Sheet Set",
        image: "https://m.media-amazon.com/images/I/71ireYzSqEL._AC_SL1500_.jpg",
        link: "https://amzn.to/46SOygS",
        wishlist: false,
        category:["appliance", "clothes"], 
      },
      {
        id: 32,
        description: "Keurig Coffee Maker",
        image: "https://m.media-amazon.com/images/I/61UTFJux1pL._AC_SX679_.jpg",
        link: "https://amzn.to/3WMDxc3",
        wishlist: false,
        category:["appliance", "kitchenware"], 
      },
  ];

export default productData;