import React from 'react';
import "./shop.scss";
import Header from '../../components/header/Header';

export function Shop() {

  return (
    <div>
        <Header />
    </div>
  );
}
