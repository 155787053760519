import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProduct } from './shopAPI';

const initialState = {
  data: [],
  filteredData: [],
  wishlistData: [],
  status: 'idle',
  openStatus: 'wishlistBoxClose',
  searchValue: '',
  compareOpenStatus: 'compareBoxClose',
};

export const getProducts = createAsyncThunk(
  'shop/fetchProducts', // Corrected type prefix
  async () => {
    const response = await fetchProduct();
    return response;
  }
);

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    openWishlist: (state, action) => {
      state.openStatus = action.payload;
    },
    searchProducts: (state, action) => {
      state.searchValue = action.payload;
      console.log(state.searchValue, ' search');
      if(state.searchValue === ''){
        state.filteredData = state.data;
      }else{
        state.filteredData = state.data.filter(item => 
          item.description && item.description.toLowerCase().includes(state.searchValue.toLowerCase())
        );
      }
    },
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
        state.filteredData = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status = 'fail';
      });
  },
});

export const { openWishlist, searchProducts } = shopSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.shop.value)`
export const selectProducts = (state) => state.shop.filteredData;
export const selectWishlistOpenStatus = (state) => state.shop.openStatus;

export default shopSlice.reducer;


//filtered data  